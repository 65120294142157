import {CalenderView} from './CalenderView';
import {CalenderViewDataSourceProtocol} from './CalenderViewDataSourceProtocol';

export class CalenderViewDelegateProtocol {
  delegateCalenderView = null;
  constructor(startDateISOstring: string) {
    new CalenderViewDataSourceProtocol(startDateISOstring);
    this.delegateCalenderView = new CalenderView();
  }

  renderShift() {
    this.renderData();
    return this.delegateCalenderView.renderTableData();
  }

  addDatatoCalender(index: number, data: any) {
    this.delegateCalenderView.addDatatoCalenderItem(index, data);
  }

  renderData() {}
}
