import * as React from 'react';
import moment from 'moment';
import {Weekday, ActionCalendar, DayOfWeek, DateGrid} from './ActionCalendarGridSystem';
import {CalenderViewItem} from './CalenderViewItem';
import {CalenderViewDataSourceProtocol} from './CalenderViewDataSourceProtocol';

export class CalenderView {
  calenderItem = [];

  constructor() {
    for (let i = 0; i < CalenderViewDataSourceProtocol.gridCount; i++) {
      this.calenderItem.push(<CalenderViewItem dateIndicatorValue={(i + 1).toString()} content={<></>} />);
    }

    for (let i = 0; i < CalenderViewDataSourceProtocol.proceedingArr; i++)
      this.calenderItem.unshift(<CalenderViewItem dateIndicatorValue="" content={<></>} />);

    for (let i = 0; i < CalenderViewDataSourceProtocol.succeedingArr; i++)
      this.calenderItem.push(<CalenderViewItem dateIndicatorValue="" content={<></>} />);
  }

  addDayOfWeek() {
    const weekdaysArr = moment.weekdaysShort();
    return (
      <DayOfWeek>
        {weekdaysArr.map((weekday, i) => (
          <Weekday key={`week-${i}`}>{weekday}</Weekday>
        ))}
      </DayOfWeek>
    );
  }

  renderTableData() {
    return (
      <ActionCalendar>
        {this.addDayOfWeek()}
        <DateGrid>
          <React.Fragment>
            {this.calenderItem.map((item, index) => {
              return this.calenderItem[index];
            })}
          </React.Fragment>
        </DateGrid>
      </ActionCalendar>
    );
  }

  addDatatoCalenderItem(index: number, data: JSX.Element) {
    this.calenderItem[CalenderViewDataSourceProtocol.proceedingArr + index] = (
      <CalenderViewItem dateIndicatorValue={String(index + 1)} content={data} />
    );
  }
}
