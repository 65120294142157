import * as React from 'react';
import {DateHolder, DateIndicator} from './ActionCalendarGridSystem';

interface Props {
  dateIndicatorValue: string;
  content: React.ReactElement;
}
export function CalenderViewItem({dateIndicatorValue, content}: Props) {
  return (
    <DateHolder>
      <DateIndicator>{dateIndicatorValue}</DateIndicator>
      {content}
    </DateHolder>
  );
}
